@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframe' as k;

.c-hero_item {
  padding-top: 115px;
  margin: 0 auto;
  animation: fade 2s ease-in-out forwards;
  @include m.pc_s {
    padding-top: 5em;
  }
  &._index {
    @include m.pc {
      width: calc(1280 / 1480 * 100%);
    }
    @include m.sp {
      width: 95%;
    }
  }
  &._pages {
    max-width: 1000px;
    width: 90%;
    img {
      border-radius: 0.7em;
    }
    @include m.sp {
      padding-top: 4em;
    }
  }
  &._none {
    @include m.sp {
      padding-top: 4em;
    }
  }
}
