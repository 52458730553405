@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-post {
  &_item {
    dl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    dt,
    dd {
      @include m.fz(12);
      line-height: 1.7;
    }
    &__date {
      width: 10%;
      @include m.tab_s {
        width: 100%;
      }
    }
    &__text {
      @include m.pc {
        width: 90%;
        display: flex;
        align-items: center;
      }
      p {
        @include m.pc {
          width: 80%;
        }
      }
    }
    &__category {
      text-align: center;
      display: inline-block;
      @include m.fz(10);
      line-height: 1.8;
      @include m.b_frame;
      margin-right: 10px;
      padding: 0 10px;
    }
    &__head {
      padding-bottom: 1em;
      margin-bottom: 3em;
      border-bottom: 1px solid v.$main;
    }
    &__contents {
      margin-bottom: 2em;
    }
    &__inner {
      width: 95%;
      margin: 0 auto 5em auto;
    }
    &__img {
      margin-bottom: 1em;
    }
    .c-btn {
      margin: 0 auto;
    }
  }
}
