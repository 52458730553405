@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-mb50 {
  margin-bottom: 50px;
  @include m.sp {
    margin-bottom: 3em;
  }
}
.u-mb40 {
  margin-bottom: 40px;
  @include m.sp {
    margin-bottom: 1.5em;
  }
}
.u-mb30 {
  margin-bottom: 30px;
  @include m.sp {
    margin-bottom: 1em;
  }
}
.u-mb20 {
  margin-bottom: 20px;
  @include m.sp {
    margin-bottom: 0.8em;
  }
}
.u-mb10 {
  margin-bottom: 10px;
  @include m.sp {
    margin-bottom: 0.5em;
  }
}
.u-m_auto {
  margin: 0 auto;
}
