@use "global/mixin" as m;
@use "global/variable" as v;

/*--------------------------------------
 form
---------------------------------------*/
.p-contact {
  padding-top: 3em;
  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    dt {
      width: 100%;
      @include m.pc {
        width: 30%;
        margin-bottom: 2em;
        display: flex;
        align-items: center;
      }
      @include m.sp {
        line-height: 1.5;
        margin-bottom: 0.5em;
      }
    }
    dd {
      width: 100%;
      @include m.pc {
        width: 70%;
        margin-bottom: 2em;
      }
      @include m.sp {
        margin-bottom: 1em;
      }
    }
    /* placeholder */
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: #ccc;
    }
    .inputs {
      width: 100%;
      height: 60px;
      @include m.sp {
        height: 50px;
      }
    }
    .must {
      @include m.fz(14);
      color: v.$green;
      margin-left: 0.5em;
      @include m.sp {
        display: block;
        margin-left: 0;
      }
    }
  }
  /* radio */
  input[type="radio"] {
    display: none;
  }
  .radio {
    position: relative;
    display: inline-block;
    padding: 0 1em 0 1.7em;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      border-radius: 100%;
      transition: all 0.2s;
    }

    &::before {
      left: 0;
      width: 18px;
      height: 18px;
      margin-top: -10px;
      background: v.$white;
      border: 1px solid v.$main;
    }
    &::after {
      opacity: 0;
      left: 3px;
      width: 12px;
      height: 12px;
      margin-top: -7px;
      background: v.$green;
    }
  }
  input[type="radio"]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  // Contact7
  .wpcf7-list-item.first {
    margin: 0;
  }
  .wpcf7-list-item-label {
    cursor: pointer;
    display: flex;
    &::before {
      background: v.$white;
      border: 1px solid v.$main;
      border-radius: 100%;
      content: "";
      height: 1.2em;
      margin-bottom: auto;
      margin-right: 0.5em;
      margin-top: auto;
      transition: background-color 0.5s;
      width: 1.2em;
    }
  }
  input[type="radio"]:checked + .wpcf7-list-item-label::before {
    background-color: v.$green;
    box-shadow: inset 0 0 0 3px #fff; /* 中心の色のスタイル */
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    outline: none;
    background-clip: padding-box;
    padding: 0.8em;
    font-size: 100%;
    @include m.b_frame;
    background: #fff;
    &:focus {
      box-shadow: 0 0 7px v.$green;
      border: 1px solid v.$green;
    }
  }
  .textarea {
    width: 100%;
    height: 150px;
    max-height: 250px;
    min-height: 250px;
    padding: 1em 0.8em 0.8em 0.8em;
    box-sizing: border-box;
  }
  &__btn {
    width: 60%;
    padding-top: 3em;
    @include m.sp {
      flex-direction: column;
    }
    a,
    .c-btn {
      &:nth-last-of-type(1) {
        @include m.pc {
          margin-left: 2em;
        }
        @include m.sp {
          margin-top: 1.5em;
        }
      }
    }
  }
  &__thanks {
    p {
      @include m.sp {
        text-align: left;
      }
    }
  }
  .c-btn {
    &:hover {
      cursor: pointer;
    }
  }
  /*-------------------------------
お問い合わせ　エラー画面　contactus error
-------------------------------*/
  .error {
    display: block;
    font-size: 1rem !important;
    font-weight: bold;
    line-height: 2em;
    text-align: left;
    margin: 0 auto 0 0;
  }
  /*-------------------------------
MW WP Form
-------------------------------*/
  .g-recaptcha {
    margin: 0 !important;
  }

  .horizontal-item {
    margin-left: 0 !important;
  }
  .mw_wp_form_input {
    .re_btn {
      display: none;
    }
  }

  .mw_wp_form_confirm {
    .must {
      display: none;
    }
    .form_content {
      padding-top: 2em;
      dt,
      dd {
        margin-bottom: 3em;
      }
      dd {
        text-align: left;
      }
    }
    .wp_form_btn {
      width: 85%;
      display: flex;
      justify-content: space-between;
    }
  }
}
