@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-box {
  padding: 1em 1.5em;
  @include m.b_frame;
  @include m.sp {
    padding: 1em 0.5em;
  }
}
.c-label {
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 5px;
  background: v.$white;
  @include m.b_frame;
  @include m.sp {
    margin-bottom: 0.2em;
  }
}
.c-b_radius {
  border-radius: 0.7em;
}
