@use 'global/mixin' as m;
@use 'global/variable' as v;

/*------------------------------------
   ▼松峰園について​ //About us
  -------------------------------------*/
.p-about {
  .l-section_fx {
    &.--2col {
      ul {
        li {
          margin-bottom: 1.5em;
          span {
            display: block;
            @include m.fz(16);
            font-weight: bold;
          }
        }
      }
    }
  }
  .bnr_item {
    p {
      text-align: center;
    }
    &_img {
      text-align: center;
    }
    a {
      display: inline-block;
      @include m.sp {
        width: 70%;
      }
    }
  }
  .btn__link {
    display: block;
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
}
