@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  @include m.fz(15);
  // font-family: v.$yugo;
  font-family: v.$noto_sans;
  font-weight: 500;
  color: v.$main;
  line-height: 2;
  letter-spacing: 0.1em;
  font-feature-settings: 'palt';
  background: #ffffff;
  @include m.sp {
    @include m.fz(14);
    line-height: 1.8;
  }
}
#container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
//--- anchor ---//
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
    -moz-opacity: 0.8;
  }
}
.tel_a:hover {
  cursor: text;
  opacity: 1;
  background: none;
  @include m.tab_s {
    cursor: pointer;
  }
}
//--- Layout ---//
.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}
.inline {
  display: inline;
}
.block {
  display: block;
}

//--- iframe_area ---//
.iframe_area {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
