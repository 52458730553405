@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-color {
  &_text {
    &--main {
      color: v.$main;
    }
    &--green {
      color: v.$green;
    }
    &--white {
      color: v.$white;
    }
    &--pink {
      color: v.$pink;
    }
  }

  &_bg {
    &--main {
      background-color: v.$main;
    }
    &--green {
      background-color: v.$green;
    }
    &--gray {
      background-color: v.$gray;
    }
  }
}
