@use "global/mixin" as m;
@use "global/variable" as v;

.u-text {
  &--indent {
    padding: 0 0 0 1em;
    text-indent: -1em;
  }
  &c {
    text-align: center;
  }
  &underline {
    text-decoration: underline;
  }
  &--underline_double {
    text-decoration: underline double;
  }
}
