@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼採用情報​ //Recruit
  -------------------------------------*/
.p-recruit {
  .contact {
    .l-section_fx {
      a {
        &:nth-of-type(1) {
          @include m.pc {
            margin-right: 50px;
          }
          @include m.sp {
            margin-bottom: 2em;
          }
        }
      }
    }
    .c-btn._large__green {
      line-height: 1.4;
      text-align: center;
    }
  }
}
