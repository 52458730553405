@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
  display: block;
  width: 160px;
  height: 50px;
  @include m.fz(17);
  font-weight: bold;
  color: v.$white;
  background: v.$main;
  @include m.b_frame;
  display: flex;
  align-items: center;
  justify-content: center;
  @include m.sp {
    @include m.fz(15);
  }
  &:hover {
    color: v.$main;
    background: v.$white;
  }
  &._large {
    width: 250px;
    &__green {
      width: 250px;
      border: 2px solid v.$green;
      background: v.$green;
      &:hover {
        color: v.$green;
        background: v.$white;
      }
    }
  }
}
