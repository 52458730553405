//--- animation ---//
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-fade {
  opacity: 0;
  &.scroll {
    animation: fade 1.5s ease-in-out forwards;
  }
}
