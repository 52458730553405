@charset "UTF-8";

/* transition */
$anime: 0.3s ease;

/* color */
// theme
$main: #5a463c;
// white
$white: #fff;
// green
$green: #839b5c;
// gray
$gray: #f3f5ef;
// pink
$pink: #e86b9e;

/* font-family */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Maru+Gothic:wght@400;500;700&display=swap');
$yugo: '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
$zen_maru: 'Zen Maru Gothic', serif;
$noto_sans: 'Noto Sans JP', sans-serif;
