@use 'global/mixin' as m;
@use 'global/variable' as v;
/*-------------------------------
  Footer
-------------------------------*/
.l-footer {
  padding: 100px 0 50px;
  @include m.sp {
    padding: 4em 0;
  }
  &_item {
    margin-bottom: 150px;
    @include m.sp {
      margin-bottom: 4em;
    }
  }
  .logo {
    @include m.sp {
      width: 70%;
      margin-bottom: 1em;
    }
  }
  &_info {
    @include m.pc {
      margin-left: 40px;
    }
    li {
      line-height: 1.3;
      margin-bottom: 20px;
      @include m.sp {
        margin-bottom: 0.5em;
      }
      span {
        @include m.pc {
          display: block;
        }
        @include m.sp {
          margin-right: 1em;
        }
      }
    }
  }
  .c-btn {
    @include m.sp {
      margin: 2em auto 0 auto;
    }
  }
  .copy {
    display: block;
    text-align: center;
    @include m.sp {
      @include m.fz(10);
    }
  }
}
// 背景設定
.f_bg_img {
  background: url(../../assets/img/common/footer_img.jpg) bottom / cover no-repeat;
}
/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 3;
  right: 1em;
  bottom: 1em;
  @include m.sp {
    right: 0.5em;
  }
  a {
    display: block;
    background: v.$main;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
    @include m.sp {
      width: 45px;
      height: 45px;
      line-height: 45px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 17px;
      height: 17px;
      border-right: 3px solid v.$white;
      border-top: 3px solid v.$white;
      transform: rotate(-45deg);
      margin-top: 3px;

      @include m.sp {
        width: 13px;
        height: 13px;
      }
    }
  }
}
