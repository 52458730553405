@use "global/mixin" as m;
@use "global/variable" as v;

.c-title {
  @include m.fz(10);
  font-weight: bold;
  @include m.lineHeight(2);
  span {
    display: block;
    @include m.fz(30);
    font-family: v.$zen_maru;
    @include m.sp {
      @include m.fz(22);
      line-height: 1.5;
    }
  }
  &--small {
    @include m.fz(13);
    font-family: v.$zen_maru;
    @include m.sp {
      @include m.fz(12);
    }
  }
  &--medium {
    @include m.fz(18);
    font-family: v.$zen_maru;
    @include m.sp {
      @include m.fz(17);
    }
  }
  &--large {
    @include m.fz(22);
    font-family: v.$zen_maru;
    @include m.sp {
      @include m.fz(18);
    }
  }
}
.c-text {
  &--small {
    @include m.fz(13);
    line-height: 1.5;
    @include m.sp {
      @include m.fz(12);
    }
  }
  &--medium {
    @include m.fz(18);
    @include m.sp {
      @include m.fz(16);
    }
  }
  &--large {
    @include m.fz(22);
    line-height: 1.4;
    @include m.sp {
      @include m.fz(18);
    }
  }
}
