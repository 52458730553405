@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-font {
  &__family_zen {
    font-family: v.$zen_maru;
  }
  &__bold {
    font-weight: bold;
  }
}
