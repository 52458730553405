@use 'global/mixin' as m;
@use 'global/variable' as v;
/*--------------------------------------
  header
---------------------------------------*/
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: v.$white;

  &_contents {
    max-width: 1390px;
    width: 95%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    @include m.pc_s {
      width: 100%;
      height: 50px;
      margin: 0.5em;
    }
    @include m.sp {
      margin: 0;
    }
  }
}
.h_logo {
  @include m.pc_s {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 5%;
    transform: translate(-5%, -50%);
  }
  @include m.sp {
    img {
      width: 70%;
    }
  }
}
.nav_contents {
  @include m.pc_s {
    display: none;
    max-width: 100%;
    height: 100vh;
    text-align: center;
    background: v.$white;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 5;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.pc_s {
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
    }
    a {
      display: block;
      position: relative;
      @include m.fz(17);
      font-weight: bold;
      line-height: 1.4;
      font-feature-settings: 'palt';
      margin-right: 1.5vw;
      @include m.pc_s {
        @include m.fz(20);
        text-align: center;
        margin-right: 0;
        margin-bottom: 1.5em;
        position: relative;
      }
      @include m.sp {
        @include m.fz(18);
      }
      span {
        display: block;
        @include m.fz(10);
        color: v.$green;
      }
    }
    .c-btn {
      display: flex;
      margin: 0;
    }
  }
}
.drawer_nav,
.menu_trigger {
  display: none;
}
@include m.pc_s {
  .drawer_nav {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(0, -50%);
    z-index: 99;
    box-sizing: border-box;
  }
  .menu_trigger {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 25px;
    vertical-align: middle;
    cursor: pointer;
    @include m.sp {
      height: 20px;
    }
    span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 2em;
      height: 3px;
      background: v.$main;
      transition: all 0.5s;
      @include m.sp {
        width: 1.7em;
        height: 2px;
      }
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 45%;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
    &.active {
      span {
        background: v.$main;
        &:nth-of-type(1) {
          transform: translateY(11px) rotate(-45deg);
          @include m.sp {
            transform: translateY(9px) rotate(-45deg);
          }
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-11px) rotate(45deg);
          @include m.sp {
            transform: translateY(-9px) rotate(45deg);
          }
        }
      }
    }
  }
}
