@use "global/mixin" as m;
@use "global/variable" as v;

.l-section {
  padding: 100px 0;
  @include m.sp {
    padding: 3em 0;
  }
  &_inner {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
  }
  &_fx {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__img {
      @include m.pc {
        width: calc(550 / 1000 * 100%);
      }
      @include m.sp {
        margin-bottom: 1em;
      }
    }
    &__col {
      @include m.pc {
        width: calc(400 / 1000 * 100%);
      }
    }
    &.--jc {
      justify-content: center;
    }
    &.--2col {
      .l-section_fx {
        &__img {
          @include m.pc {
            flex: 1;
            margin-left: calc(50% - 50vw);
            margin-right: 10%;
          }
          @include m.pc_s {
            margin-right: 5%;
          }
          @include m.sp {
            margin-right: 0;
          }
          img {
            @include m.sp {
              border-radius: 0.7em;
            }
          }
        }
        &__col {
          width: 100%;
          text-align: left;
          @include m.pc {
            width: 51%;
          }
        }
      }
    }
    &.--3col {
      @include m.pc {
        margin: -25px;
        justify-content: flex-start;
      }
      .l-section_fx {
        &_col {
          @include m.pc {
            width: calc((100% / 3) - 50px);
            margin: 25px;
          }
          @include m.sp {
            width: 100%;
            margin: 1em auto;
          }
          &:hover {
            .l-section_fx_col__img {
              img {
                transform: scale(1.2, 1.2);
                transition: 2s all;
              }
            }
          }

          p {
            font-weight: bold;
            span {
              display: block;
            }
          }
          &__img {
            margin-bottom: 20px;
            overflow: hidden;
            border-radius: 0.7em;
            @include m.sp {
              margin-bottom: 1em;
            }
            img {
              transition: 2s all;
              @include m.sp {
                width: 100%;
                height: 250px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
  &_head {
    margin-bottom: 120px;
    @include m.sp {
      margin-bottom: 3em;
    }
  }
}
