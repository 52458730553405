@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼施設の案内  //facility
  -------------------------------------*/
.p-facility {
  .l-section_fx {
    &.--3col {
      .l-section_fx_col__img {
        img {
          object-fit: cover;
          width: 100%;
          height: 180px;
        }
      }
    }
  }

  /*------------------------------------
   ▼施設の案内 詳細
  -------------------------------------*/
  .facility {
    dt {
      &:nth-of-type(1) {
        padding-top: 0;
      }
    }
    dd {
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
  /*------------------------------------
   ▼サービス内容・プログラム​//service
  -------------------------------------*/
  .service {
    .l-section_fx {
      margin: 0 -20px;
      &__col {
        width: calc((100% / 3) - 40px);
        margin: 20px;
      }
    }
  }
  /*------------------------------------
   ▼館内の案内​​ //Guidance
  -------------------------------------*/
  .guidance {
    .l-section_fx {
      &__col {
        margin-bottom: 60px;
        @include m.pc {
          width: calc(475 / 1000 * 100%);
        }
        @include m.sp {
          margin-bottom: 2em;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
        figure {
          margin-bottom: 10px;
          @include m.sp {
            margin-bottom: 0.2em;
          }
          img {
            border-radius: 0.7em;
          }
          figcaption {
            @include m.fz(20);
            font-weight: bold;
            padding-top: 0.3em;
            text-align: center;
            @include m.sp {
              @include m.fz(18);
            }
          }
        }
      }
    }
  }
  /*------------------------------------
   ▼館内の設備​​ //Facilities
  -------------------------------------*/
  .facilities {
    .l-section_inner {
      max-width: 740px;
      width: 90%;
      margin: 0 auto;
    }
    .l-section_fx {
      &.--jc {
        margin: 0 -20px;
      }
      &__col {
        background: v.$white;
        text-align: center;
        border-radius: 0.7em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include m.pc {
          width: calc((100% / 3) - 40px);
          height: 158px;
          margin: 20px;
        }
        @include m.sp {
          width: 85%;
          padding: 1em;
          margin-bottom: 1em;
        }
        p {
          font-family: v.$zen_maru;
          @include m.fz(22);
          line-height: 1.4;
          @include m.sp {
            @include m.fz(18);
          }
          span {
            @include m.fz(15);
            @include m.pc {
              display: block;
            }
          }
        }
      }
    }
  }
  .btn_none {
    pointer-events: none;
  }
}
