@use "global/mixin" as m;
@use "global/variable" as v;

.p-index {
  @include m.sp {
    .l-section_fx__img {
      width: 100%;

      img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 0.5em;
      }
    }
  }
  /*------------------------------------
   ▼施設の案内 //Facility
  -------------------------------------*/
  .facility {
    .l-section {
      &_fx {
      }
    }
  }

  /*------------------------------------
   ▼ご家族・入居者の声​ //Voice
  -------------------------------------*/
  .voice {
    margin-bottom: 90px;
    .l-section_inner {
      max-width: 660px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: auto;
        padding-top: 40%;
        background: url(../../assets/img/index/voice_img.png) left / contain no-repeat;
        position: absolute;
        bottom: -25%;
        left: -5%;
        z-index: -1;
        @include m.sp {
          padding-top: 30%;
          left: 0;
        }
      }
    }
    &_item {
      max-width: 510px;
      margin: 0 auto 30px auto;
      padding: 30px;
      @include m.b_frame;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include m.sp {
        width: 80%;
        padding: 1em;
        margin-bottom: 1em;
      }
      &:nth-of-type(odd) {
        margin-left: 0;
      }
      &:nth-of-type(even) {
        margin-right: 0;
      }
      p {
        font-family: v.$zen_maru;
        @include m.fz(17);
        font-weight: bold;
        @include m.sp {
          @include m.fz(15);
        }
        span {
          display: block;
          @include m.fz(12);
          color: v.$green;
        }
      }
    }
  }
  /*------------------------------------
   ▼見学予約・お問合わせ​​ //Contact
  -------------------------------------*/
  .contact {
    .l-section_inner {
      max-width: 650px;
    }
    .l-section_fx {
      @include m.sp {
        justify-content: center;
      }
    }
    a {
      width: 300px;
      height: 110px;
      @include m.fz(26);
      font-weight: bold;
      font-family: v.$zen_maru;
      background: v.$white;
      @include m.b_frame;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include m.sp {
        width: 70%;
        height: 60px;
        @include m.fz(18);
      }
      &::before {
        content: "";
        display: block;
        width: 36px;
        height: 44px;
        margin: 0.2em 0.3em 0 0;
        @include m.sp {
          width: 26px;
        }
      }
    }
    .contact_a {
      letter-spacing: -0.01em;
      @include m.sp {
        margin-bottom: 0.7em;
      }
      &::before {
        background: url(../../assets/img/common/mail_icon.svg) center / contain no-repeat;
      }
    }
    .tel_a {
      background: v.$main;
      &::before {
        background: url(../../assets/img/common/tel_icon.svg) center / contain no-repeat;
      }
      &_text {
        @include m.fz(28);
        letter-spacing: 0.1em;
        line-height: 1.4;
        color: v.$white;
        @include m.sp {
          @include m.fz(20);
          line-height: 1.2;
        }
        span {
          display: block;
          @include m.fz(15);
          font-family: v.$zen_maru;
        }
      }
    }
  }
  /*------------------------------------
   お知らせ​​ //News
  -------------------------------------*/
  .news {
    padding-bottom: 0;
  }
  /*------------------------------------
   カフェ予約​​ //Cafe reservation 
  -------------------------------------*/
  .reservation {
  }
  /*------------------------------------
   採用情報​​ //Recruit
  -------------------------------------*/
  .recruit {
    text-align: center;

    .c-btn {
      margin: 0 auto;
    }
  }
}
