@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-news {
  .c-post_item {
    dl {
      padding-bottom: 1em;
      border-bottom: 1px solid v.$main;
    }
    dt,
    dd {
      @include m.fz(14);
      line-height: 1.7;
    }
  }
  .pagination {
    width: 95%;
    margin: 3em auto 0 auto;
    display: flex;
    justify-content: center;

     &_inner,
    .wp-pagenavi {
      display: flex;
      text-align: center;
    }
    span,
    a,
    .page {
      display: block;
      width: 35px;
      height: 35px;
      line-height: 33px;
      border-radius: 50%;
      margin-right: 1em;
      background: v.$white;
      position: relative;
      @include m.sp {
        width: 30px;
        height: 30px;
        line-height: 28px;
        margin-right: 0.7em;
      }
    }
    a {
      &:hover {
        color: #fff;
        background: v.$main;
      }
    }
    .previouspostslink,
    .nextpostslink {
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30%;
        margin-top: -4px;
        width: 7px;
        height: 7px;
        border-right: 2px solid v.$main;
        border-bottom: 2px solid v.$main;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .previouspostslink {
      &:before {
        transform: rotate(135deg);
      }
    }
    .nextpostslink {
      &:before {
        transform: rotate(-45deg);
      }
    }

    .current {
      color: #fff;
      background: v.$main;
    }
  }
}
